import { useEffect, useState } from 'react';
import { socket } from 'config';
import { IFight } from '../types';
import FightCard from './FightCard';
import useGetCollection from '../../../store/hooks/getCollection';

const DisplayFights = () => {
  const [fights, setFights] = useState<IFight[]>([]);
  const [loading, setLoading] = useState<boolean>(true);
  const { actualCollections } = useGetCollection();
  useEffect(() => {
    if (socket === null) return;
    socket.emit(
      'getFights',
      {
        collections:
          actualCollections.length > 1
            ? actualCollections[0] + '&' + actualCollections[1]
            : actualCollections[0]
      },
      (response: any) => {
        if (response.status === 'SUCCESS') {
          setFights(response.data);
          setLoading(false);
        }
      }
    );
  }, []);

  socket.on('newFight', (response: any) => {
    const intermediaryFights = fights.map((fight) =>
      fight.uniqueId === response.uniqueId ? response.fight : fight
    );
    setFights(intermediaryFights);
  });

  if (loading)
    return (
      <div className='row'>
        <div className='col-12'>
          <div className='d-flex justify-content-center'>
            <div className='spinner-border text-white' role='status'></div>
          </div>
        </div>
      </div>
    );

  if (fights.length < 1) {
    return (
      <div className='row'>
        <div className='col-12'>
          <h4 className='text-white text-center mb-5'>
            The battles have not been created yet!
          </h4>
          <hr className='fight-page-card-lines' />
        </div>
      </div>
    );
  }
  return (
    <div className='row'>
      {fights?.map((fight: IFight) => (
        <div
          key={fight.uniqueId}
          className='col-12 col-sm-6 col-md-4 mb-2 mb-md-0'
        >
          <FightCard fight={fight} />
        </div>
      ))}
    </div>
  );
};

export default DisplayFights;
