import axios from 'axios';
import CustomModal from 'components/CustomModal';
import { useReducer, useState } from 'react';
import { formReducer, initialState } from '../reducer/formReducer';
import useGetCollection from '../../../store/hooks/getCollection';
import {
  API_BACKEND_URL,
  ADMIN_USERNAME,
  ADMIN_PASSWORD
} from '../../../config';

export const CreateCollectionForm = () => {
  const [state, dispatch] = useReducer(formReducer, initialState);
  const { setCollections } = useGetCollection();
  const [show, setShow] = useState(false);
  const [error, setError] = useState('');
  const [waitResponse, setWaitResponse] = useState({
    status: '',
    message: ''
  });

  const handleChange = (e: any) => {
    dispatch({
      type: 'change_input',
      payload: { name: e.target.name, value: e.target.value }
    });
    setError('');
  };
  const sendInformation = () => {
    if (
      state.firstIdentifier === '' ||
      state.secondIdentifier === '' ||
      state.imageUrl === ''
    ) {
      setError('Completeaza toate casetele!');
      return;
    }
    if (state.firstIdentifier.length < 3) {
      setError('Collection Identifier #1 must contain more than 3 characters!');
      return;
    }
    if (state.secondIdentifier.length < 3) {
      setError('Collection Identifier #1 must contain more than 3 characters!');
      return;
    }
    if (state.imageUrl.length < 3) {
      setError('Image URL #1 must contain more than 3 characters!');
      return;
    }
    if (state.startId < 1) {
      setError('The start ID must be greater than 1!');
      return;
    }
    setShow(true);
  };

  const createCollection = async () => {
    try {
      const { data } = await axios.post(
        API_BACKEND_URL + '/admin/collection',
        {
          ...state
        },
        {
          headers: {
            username: ADMIN_USERNAME,
            password: ADMIN_PASSWORD
          }
        }
      );
      if (data.status === 'SUCCESS') {
        setWaitResponse({
          status: data.status,
          message: 'Collection created successfully!'
        });
        setCollections(data.data.collections);
      } else {
        setWaitResponse(data);
      }
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <>
      <div onSubmit={() => sendInformation()}>
        <div className='form-group'>
          <label>Collection Identifier #1</label>
          <input
            type='text'
            className='form-control'
            name='firstIdentifier'
            onChange={handleChange}
          />
        </div>
        <div className='form-group'>
          <label>Collection Identifier #2</label>
          <input
            type='text'
            className='form-control'
            name='secondIdentifier'
            onChange={handleChange}
          />
        </div>
        <div className='form-group'>
          <label>ImageUrl</label>
          <input
            type='text'
            className='form-control'
            placeholder='Insert a image link!'
            name='imageUrl'
            onChange={handleChange}
          />
        </div>
        <div className='form-group'>
          <label>StartID</label>
          <input
            type='number'
            className='form-control'
            name='startId'
            onChange={handleChange}
          />
        </div>
        {error && <p className='form-text text-danger my-2'>{error}</p>}
        <button className='btn btn-primary' onClick={() => sendInformation()}>
          Create
        </button>
      </div>
      <CustomModal
        show={show}
        setShow={() => {
          setShow(false);
          setWaitResponse({ status: '', message: '' });
        }}
        backdrop={false}
        title={
          <h4 className='text-center'>
            {waitResponse.status === ''
              ? 'This informations are correct?'
              : waitResponse.status}
          </h4>
        }
      >
        {waitResponse.status === '' ? (
          <DisplayCollectionInformations
            informations={state}
            setShow={setShow}
            func={createCollection}
          />
        ) : (
          <h4
            className={
              waitResponse.status !== 'FAIL' ? 'text-white' : 'text-danger'
            }
          >
            {waitResponse.message}
          </h4>
        )}
      </CustomModal>
    </>
  );
};

const DisplayCollectionInformations = ({
  informations,
  setShow,
  func
}: {
  informations: any;
  setShow: (show: any) => void;
  func: () => void;
}) => {
  return (
    <div className='p-2'>
      <div className='mb-3'>
        <h4>Collection Identifier #1</h4>
        <h5>{informations.firstIdentifier}</h5>
      </div>
      <hr className='fight-page-card-lines' />
      <div className='mb-3'>
        <h4>Collection Identifier #2</h4>
        <h5>{informations.secondIdentifier}</h5>
      </div>
      <hr className='fight-page-card-lines' />
      <div className='mb-3'>
        <h4>Image URL</h4>
        <h5>{informations.imageUrl}</h5>
      </div>
      <hr className='fight-page-card-lines' />
      <div className='mb-3'>
        <h4>Start ID</h4>
        <h5>{informations.startId}</h5>
      </div>
      <button className='btn btn-success' onClick={func}>
        Yes
      </button>
      <button className='btn btn-danger ml-2' onClick={() => setShow(false)}>
        No
      </button>
    </div>
  );
};
