export function decreaseStrings(
  str: string | undefined,
  leftChars: number,
  rightChars: number
): string {
  if (!str) return 'NULL';
  return (
    str.substring(0, leftChars) +
    '...' +
    str.substring(str.length - rightChars, str.length)
  );
}
