import React, { useEffect, useState } from 'react';
import axios from 'axios';

const NftItem = ({
  identifier,
  name
}: {
  identifier: string;
  name: string;
}) => {
  const [nftImage, setNftImage] = useState<string>('');

  const fetchNftImage = async () => {
    try {
      const { data } = await axios.get(
        'https://proxy-api.xoxno.com/nfts/' + identifier
      );
      setNftImage(data.webpUrl);
    } catch {
      console.log('Error when i try to fetch nftImage.');
    }
  };

  useEffect(() => {
    fetchNftImage();
  }, []);
  if (nftImage === '') return null;

  return (
    <div>
      <img src={nftImage} alt={identifier} width='100%' />
      <p className='text-center mt-3'>{name}</p>
    </div>
  );
};

export default NftItem;
