import React, { useEffect, useState } from 'react';
import { socket } from 'config';
import { Table } from 'react-bootstrap';
import { IFight } from '../../Fight/types';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye } from '@fortawesome/free-solid-svg-icons';
import { Link } from 'react-router-dom';
import { decreaseStrings } from 'utils/decreaseStrings';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';

const FightsTable = () => {
  const { address } = useGetAccountInfo();
  const [fights, setFights] = useState<IFight[]>([]);

  useEffect(() => {
    if (socket === null) return;
    socket.emit('getHistoryOfFights', { maxSize: 20 }, (response: any) => {
      if (response.status === 'SUCCESS') {
        setFights(response.data);
      }
    });

    socket.on(
      'newFightInHistory',
      (response: { status: string; data: IFight }) => {
        if (response.status === 'SUCCESS') {
          setFights((prev) => [response.data, ...prev.slice(0, 19)]);
        }
      }
    );
  }, []);

  return (
    <Table className='mt-4 text-white'>
      <thead>
        <tr>
          <th>ID</th>
          <th>PRIZE</th>
          <th>FEE</th>
          <th>WINNER</th>
          <th>VIEW</th>
        </tr>
      </thead>
      <tbody>
        {fights?.map((fight, id) => (
          <tr key={fight.uniqueId + id}>
            <td>{fight.uniqueId}</td>
            <td>
              <span className='text-blue'>{fight.prizePool}</span> ESTAR
            </td>
            <td>
              <span className='text-blue'>{fight.entryFee}</span> ESTAR
            </td>
            <td
              className={
                fight.winner?.address === address ? 'text-blue' : undefined
              }
            >
              {decreaseStrings(fight.winner?.address, 5, 5)}
            </td>
            <td className='d-block text-center'>
              <Link
                to={'/history/fight/' + fight.uniqueId}
                className='text-white'
              >
                <FontAwesomeIcon icon={faEye} />
              </Link>
            </td>
          </tr>
        ))}
      </tbody>
    </Table>
  );
};

export default FightsTable;
