import { useEffect, useState } from 'react';
import { socket } from '../../config';
import useGetCollection from '../../store/hooks/getCollection';

export const Dominance = () => {
  const { allCollections } = useGetCollection();
  return (
    <div className='container'>
      <div className='row my-2'>
        <div className='col-12'>
          <h1 className='page-title text-center'>Dominance</h1>
          <hr className='page-title-line' />
        </div>
      </div>
      <div className='row'>
        {allCollections.map((collection: any, index) => (
          <DominanceItem collection={collection} key={index} />
        ))}
      </div>
    </div>
  );
};

const DominanceItem = ({ collection }: { collection: string }) => {
  const collectionArray = collection.split('&');
  const [dominance, setDominance] = useState<
    {
      identifier: string;
      wins: number;
      total: number;
    }[]
  >([]);
  const [total, setTotal] = useState(0);
  useEffect(() => {
    if (!socket) return;
    socket.emit('getDominance', { collection }, (response: any) => {
      if (response.status === 'SUCCESS') {
        setDominance(response.data);
      }
    });
  }, []);

  useEffect(() => {
    if (dominance.length < 1) return;
    setTotal(dominance[0].wins + dominance[1].wins);
  }, [dominance]);

  if (dominance.length < 1) return null;

  return (
    <div className='col-12 mt-4'>
      <h4 className='dominance-item-title text-center mb-4'>
        <span className='text-red'>{collectionArray[0].split('-')[0]}</span> VS{' '}
        <span className='text-blue'>{collectionArray[1].split('-')[0]}</span>
      </h4>
      <div
        className='progress mb-3'
        style={{ height: '35px', borderRadius: '20px' }}
      >
        <div
          className='progress-bar bg-red progress-bar-text'
          role='progressbar'
          style={{
            width: `${(dominance[0].wins / total) * 100}%`,
            fontSize: '20px'
          }}
        >
          {dominance[0].wins > 0
            ? `${((dominance[0].wins / total) * 100).toFixed(2)}%`
            : null}
        </div>
        <div
          className='progress-bar bg-blue text-dark progress-bar-text'
          role='progressbar'
          style={{
            width: `${(dominance[1].wins / total) * 100}%`,
            fontSize: '20px'
          }}
        >
          {dominance[1].wins > 0
            ? `${((dominance[1].wins / total) * 100).toFixed(2)}%`
            : null}
        </div>
      </div>
    </div>
  );
};
