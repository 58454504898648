import { useState } from 'react';
import { IFight, IPlayer } from '../types';
import FightPlayers from './FightPlayers';
import FightButton from './FightButton';

const FightCard = ({ fight }: { fight: IFight }) => {
  const [players, setPlayers] = useState<IPlayer[]>(fight.players);
  const id: string[] = fight.uniqueId.split('#');
  return (
    <div className='fight-page-card'>
      <h5 className='fight-page-card-id'>
        Fight <span className='text-blue'>{id[0]}</span>#{id[1]}
      </h5>
      <div className='fight-page-card-body'>
        <div className='fight-page-card-body-players'>
          <h4 className='fight-page-card-body-title'>Players</h4>
          <hr className='fight-page-card-lines' />
          <FightPlayers
            uniqueId={fight.uniqueId}
            players={players}
            setPlayers={setPlayers}
          />
        </div>
        <h4 className='fight-page-card-body-title'>Prize Pool</h4>
        <hr className='fight-page-card-lines' />
        <h5 className='fight-page-card-body-subtitle'>
          <span className='text-blue'>
            {(Number(fight.prizePool) * 90) / 100}
          </span>{' '}
          $ESTAR
        </h5>
        <h4 className='fight-page-card-body-title'>Entry Fee</h4>
        <hr className='fight-page-card-lines' />
        <h5 className='fight-page-card-body-subtitle'>
          <span className='text-blue'>{fight.entryFee}</span> $ESTAR
        </h5>
        <FightButton
          className='d-block mx-auto'
          uniqueId={fight.uniqueId}
          entryFee={fight.entryFee}
          players={players}
        />
      </div>
    </div>
  );
};

export default FightCard;
