import { RouteType } from '@multiversx/sdk-dapp/types';
import { dAppName } from 'config';
import { withPageTitle } from './components/PageTitle';

import {
  Home,
  Fight,
  History,
  ViewFight,
  Hub,
  Dominance,
  Admin
} from './pages';

export const routeNames = {
  home: '/',
  hub: '/hub',
  fight: '/fight/:collections',
  viewFight: '/history/fight/:uniqueId',
  history: '/history',
  dominance: '/dominance',
  admin: '/admin',
  unlock: '/unlock'
};

interface RouteWithTitleType extends RouteType {
  title: string;
}

export const routes: RouteWithTitleType[] = [
  {
    path: routeNames.home,
    title: 'Home',
    component: Home
  },
  {
    path: routeNames.hub,
    title: 'Hub',
    component: Hub
  },
  {
    path: routeNames.fight,
    title: 'Fight',
    component: Fight
  },
  {
    path: routeNames.viewFight,
    title: 'View Fight',
    component: ViewFight
  },
  {
    path: routeNames.history,
    title: 'History',
    component: History
  },
  {
    path: routeNames.dominance,
    title: 'Dominance',
    component: Dominance
  },
  {
    path: routeNames.admin,
    title: 'Admin',
    component: Admin
  }
];

export const mappedRoutes = routes.map((route) => {
  const title = route.title
    ? `${route.title} • ApeWars ${dAppName}`
    : `ApeWars ${dAppName}`;

  const requiresAuth = Boolean(route.authenticatedRoute);
  const wrappedComponent = withPageTitle(title, route.component);

  return {
    path: route.path,
    component: wrappedComponent,
    authenticatedRoute: requiresAuth
  };
});
