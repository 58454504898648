import React from 'react';
import FightsTable from './Components/FightsTable';

export const History = () => {
  return (
    <div className='container'>
      <div className='row my-4'>
        <div className='col-12'>
          <h1 className='text-center page-title'>History</h1>
          <hr className='page-title-line' />
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-lg-6'>
          <h1 className='text-center page-subtitle'>Fights</h1>
          <div className='overflow-auto'>
            <FightsTable />
          </div>
        </div>
        <div className='col-12 col-lg-6'>
          <h1 className='text-center page-subtitle'>Tournaments</h1>
        </div>
      </div>
    </div>
  );
};
