import { FC } from 'react';
import { Modal } from 'react-bootstrap';
import { ICustomModal } from './types';

const CustomModal: FC<ICustomModal> = ({
  show,
  setShow,
  backdrop,
  title,
  children,
  footer,
  size
}) => {
  return (
    <Modal
      show={show}
      backdrop={backdrop || undefined}
      keyboard={false}
      size={size ? size : 'xl'}
      onHide={!backdrop ? () => setShow(false) : () => {}}
      aria-labelledby='contained-modal-title-vcenter'
      centered
    >
      <Modal.Header>
        <Modal.Title
          id='contained-modal-title-vcenter'
          className='d-block mx-auto mt-1'
        >
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className='px-3'>{children}</Modal.Body>
      {footer ? <Modal.Footer className='px-3'>{footer}</Modal.Footer> : null}
    </Modal>
  );
};

export default CustomModal;
