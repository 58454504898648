import React from 'react';
import Deposit from './Components/Deposit';
import Balance from './Components/Balance';
// import Rewards from './Components/Rewards';

const Account = () => {
  return (
    <div className='col-12'>
      <div className='fight-page-account-card my-4'>
        <div className='fight-page-card-body d-md-flex justify-content-between'>
          <Deposit />
          <div className='d-block pt-5 pt-md-0'>
            <Balance />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Account;
