import { io } from 'socket.io-client';

export const reciveAddress =
  'erd13ehqwps6rtfwaj8l95h2nuahdtxw96ukrmmzrxv5qzjakqv2cc7qpas6gt';

export const dAppName = 'xArena';

// Generate your own WalletConnect 2 ProjectId here: https://cloud.walletconnect.com/app
export const walletConnectV2ProjectId = 'b2df86b17db55ccd03989c51cc2720cf';

export const apiTimeout = 6000;
export const transactionSize = 15;
export const API_URL = 'https://api.multiversx.com';
export const API_BACKEND_URL = 'https://xarena.estar.games:3040';

export const ADMIN_USERNAME = 'estar.games';
export const ADMIN_PASSWORD = '$CpHMRkUMBD3o$04ftBN';

export const ESTAR_IDENTIFIER = 'ESTAR-461bab';
/**
 * Calls to these domains will use `nativeAuth` Baerer token
 */
export const sampleAuthenticatedDomains = [API_URL];

export const socket = io('https://xarena.estar.games:3040');

export const adminList = [
  'erd1q07w9xm8avd7kwj3cgn3xrnhzg5da7e3vg7dv6gs3npyql0jpq9ss35a20',
  'erd1kc4z8r2ztk9vqhy3awr3chtly3sr928juurlvgl37s38y29rxlmswkd7az',
  'erd1368tpzxwzsgz3vtd58s2dyukfwwcmn0skpajt5qp0tk9u8y284zql5fmlg',
  'erd19wjjxty40r6356r5mzjf2fmg8we2gxzshltunntk5tg45pl35r7ql8yzym'
];
