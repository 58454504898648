import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { decreaseStrings } from 'utils/decreaseStrings';
import { IPlayer } from '../../../Fight/types';
import { INft } from '../../../../types/index';

const FightPlayer = ({
  player,
  first
}: {
  player: IPlayer;
  first?: boolean;
}) => {
  const [nftDetails, setNftDetails] = useState<INft>();

  const fetchNftDetails = async () => {
    try {
      const { data } = await axios.get(
        'https://proxy-api.xoxno.com/nfts/' + player.ape
      );
      setNftDetails(data);
    } catch {
      return null;
    }
  };

  useEffect(() => {
    fetchNftDetails();
  }, [player]);

  if (!nftDetails) {
    return (
      <div className='col-12 col-md-6'>
        <div className='d-flex justify-content-center'>
          <div className='spinner-border text-white' role='status'></div>
        </div>
      </div>
    );
  }

  return (
    <div className='d-block'>
      <h3
        className={`text-left text-md-${
          first ? 'left' : 'right'
        } fight-page-players-position`}
      >
        {first ? 'First' : 'Second'} Player
      </h3>
      <img
        className='my-3 fight-page-players-image'
        src={nftDetails?.webpUrl}
        alt={player.ape}
        width={'100%'}
      />
      <h5 className='text-center text-white'>
        {decreaseStrings(player.address, 10, 10)}
      </h5>
      <h5 className='text-center text-white'>HP: {player.hp}</h5>
    </div>
  );
};

export default FightPlayer;
