import { socket } from 'config';
import { createContext, useEffect, useState } from 'react';
import { Account } from '../types';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';

export const AccountContext = createContext<Account>({
  address: null,
  balance: 0
});

export const AccountProvider = ({ children }: { children: JSX.Element }) => {
  const { address } = useGetAccountInfo();
  const [balance, setBalance] = useState<number>(0);

  useEffect(() => {
    if (socket === null) return;
    socket.emit('getUserBalance', { address }, (response: any) => {
      if (response.status === 'SUCCESS') {
        setBalance(response.data);
      }
    });

    socket.on('setUserBalance', (response: any) => {
      if (response.address === address) {
        setBalance(response.amount);
      }
    });
  }, []);
  return (
    <AccountContext.Provider value={{ address, balance }}>
      {children}
    </AccountContext.Provider>
  );
};
