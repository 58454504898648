import React, { useEffect, useState } from 'react';
import { socket } from 'config';
import { useLocation } from 'react-router-dom';
import { IFight } from '../../Fight/types';
import FightPlayer from './Components/FightPlayer';
import FightEvents from './Components/FightEvents';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';

const ViewFight = () => {
  const { pathname, hash } = useLocation();
  const { address } = useGetAccountInfo();
  const uniqueId = pathname.slice(15) + hash;

  const messageForTweet = '⚔️ Check out this great fight. ⚔️';

  const [loading, setLoading] = useState<boolean>(true);
  const [fight, setFight] = useState<IFight>();

  useEffect(() => {
    if (socket === null) return;
    socket.emit('getFightFromHistory', { uniqueId }, (response: any) => {
      if (response.status === 'SUCCESS') {
        setFight(response.data);
        setLoading(false);
      } else if (response.status === 'FAIL') {
        setLoading(false);
      }
    });
  }, [pathname, hash]);

  if (loading) {
    return (
      <div className='container'>
        <div className='row my-5'>
          <div className='col-12'>
            <div className='d-flex justify-content-center'>
              <div className='spinner-border' role='status'></div>
            </div>
          </div>
        </div>
      </div>
    );
  }

  if (!fight)
    return (
      <div className='container my-5'>
        <div className='row'>
          <div className='col-12'>
            <h1 className='text-center page-title'>
              Fight {uniqueId} not found.
            </h1>
          </div>
        </div>
      </div>
    );
  return (
    <div className='container my-5'>
      {fight.winner?.address === address ? (
        <>
          <div className='row'>
            <div className='col-12'>
              <h1 className='page-title text-small'>Congratulations!</h1>
              <hr className='page-title-line' />
              <h5 className='page-subtitle text-center'>
                <span className='text-blue'>You won</span> {uniqueId} fight.
              </h5>
              <a
                href={`https://twitter.com/intent/tweet?text=${messageForTweet}
                &url=${
                  'http://xarena.estar.games' + pathname + '%23' + hash.slice(1)
                }
                &hashtags=xarena,estargames`}
                className='text-decoration-none'
              >
                <button className='btn btn-custom-2 d-block mx-auto'>
                  Share to Community
                </button>
              </a>
            </div>
          </div>
        </>
      ) : (
        <div className='row mb-4'>
          <div className='col-12'>
            <h1 className='text-center page-title'>Fight {uniqueId}</h1>
            <hr className='page-title-line' />
          </div>
        </div>
      )}
      <div className='row'>
        <div className='col-12 d-block d-md-flex justify-content-between align-items-center'>
          <FightPlayer player={fight.players[0]} first={true} />
          <h1 className='d-none d-md-block px-4'>
            <span className='versus-1'>V</span>
            <span className='versus-2'>S</span>
          </h1>
          <FightPlayer player={fight.players[1]} />
        </div>
      </div>
      <hr />
      <div className='row'>
        <div className='col-12'>
          <h4 className='text-center fight-page-logs mb-4'>
            <span className='text-blue'>Fight</span> Logs
          </h4>
          <FightEvents events={fight.events} />
        </div>
      </div>
    </div>
  );
};

export { ViewFight };
