import React, { useEffect, useState } from 'react';
import {
  useGetAccountInfo,
  useTrackTransactionStatus
} from '@multiversx/sdk-dapp/hooks';
import { sendTransactions } from '@multiversx/sdk-dapp/services';
import axios from 'axios';
import { API_URL, reciveAddress, socket } from 'config';
import { ESTAR_IDENTIFIER } from '../../../../../config';
import { strHex } from 'utils/strHex';
import { numHex } from 'utils/numHex';

const Deposit = () => {
  const { address } = useGetAccountInfo();
  const [txSesionId, setTxSessionId] = useState<string>('');
  const [estarForDeposit, setEstarForDeposit] = useState<number>(0);
  const [estarAvailable, setEstarAvailable] = useState<string>('0');
  const [error, setError] = useState<string>('');
  const [disable, setDisable] = useState<boolean>(false);

  const fetchEstar = async () => {
    try {
      const { data } = await axios.get(
        API_URL + '/accounts/' + address + '/tokens/' + ESTAR_IDENTIFIER
      );
      if (data.balance === undefined) return;
      setEstarAvailable(data.balance.slice(0, -18));
    } catch {
      console.log('Error fetchEstar');
    }
  };
  useEffect(() => {
    fetchEstar();
  }, []);

  const handleChange = (value: number) => {
    if (value <= Number(estarAvailable) && !disable) {
      setEstarForDeposit(value);
      setError('');
    } else {
      setError("I don't have enough $ESTAR.");
    }
  };

  const setMax = () => {
    if (Number(estarAvailable) > 0 && !disable) {
      setEstarForDeposit(Number(estarAvailable));
    }
    if (error) {
      setError('');
    }
  };

  useTrackTransactionStatus({
    transactionId: txSesionId,
    onSuccess: () => sendEstarInBalance(estarForDeposit),
    onCancelled: () => setDisable(false),
    onFail: () => setDisable(false)
  });

  const deposit = async (estar: number) => {
    const { sessionId } = await sendTransactions({
      transactions: [
        {
          value: '0',
          data:
            'ESDTTransfer@' +
            strHex(ESTAR_IDENTIFIER) +
            '@' +
            numHex(estar * 1000000000000000000),
          receiver: reciveAddress,
          gasLimit: 60000000
        }
      ]
    });
    setDisable(true);
    if (sessionId) {
      setTxSessionId(sessionId);
    }
  };

  const sendEstarInBalance = (estar: number) => {
    socket.emit(
      'userDeposit',
      { address, amount: estar },
      (response: any) => {}
    );
    setDisable(false);
  };

  return (
    <div>
      <h4 className='fight-page-account-card-body-title'>Deposit</h4>
      <h6 className='fight-page-account-card-body-subtitle'>
        Deposit <span className='text-blue'>$ESTAR</span> to join the fights.
      </h6>
      <div className='input-group'>
        <input
          type='number'
          className='form-control fight-page-account-card-body-input'
          placeholder='Deposit amount...'
          aria-describedby='basic-addon1'
          disabled={disable}
          value={
            estarForDeposit === Number(estarAvailable)
              ? estarForDeposit
              : undefined
          }
          onChange={(e) => handleChange(Number(e.target.value))}
        />
        <div className='input-group-append'>
          <span
            className='input-group-text fight-page-account-card-body-input-max'
            onClick={() => setMax()}
          >
            MAX
          </span>
        </div>
      </div>
      {error ? <span className='text-danger'>{error}</span> : undefined}
      <h6 className='py-1'>{estarAvailable} ESTAR available</h6>
      <button
        className='btn btn-custom mt-3'
        disabled={estarForDeposit === 0 || Boolean(error) || disable}
        onClick={() => deposit(estarForDeposit)}
      >
        {disable ? 'Waiting for tx...' : 'Deposit'}
      </button>
    </div>
  );
};

export default Deposit;
