import React, { useState } from 'react';
import { faDollar, faUser } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as SwordIcon } from 'assets/img/swords.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IPlayer } from '../types';
import JoinFight from './JoinFight';
import getAccount from 'store/hooks/getAccount';
const FightButton = ({
  uniqueId,
  entryFee,
  players,
  className
}: {
  uniqueId: string;
  entryFee: number;
  players: IPlayer[];
  className?: string;
}) => {
  const { address, balance } = getAccount();
  const [show, setShow] = useState<boolean>(false);

  if (
    players.filter((player: IPlayer) => player.address === address).length > 0
  ) {
    return (
      <button className={`btn btn-custom ${className}`} disabled={true}>
        <FontAwesomeIcon icon={faUser} /> Waiting for oponent
      </button>
    );
  }

  if (balance < entryFee) {
    return (
      <button className={`btn btn-custom ${className}`} disabled={true}>
        <FontAwesomeIcon icon={faDollar} /> Not enough estar.
      </button>
    );
  }

  return (
    <>
      <button
        className={`btn btn-custom ${className}`}
        disabled={players.length === 2 && true}
        onClick={() => setShow(true)}
      >
        <SwordIcon /> <span className='mx-1'>Fight</span>
      </button>
      <JoinFight
        show={show}
        setShow={setShow}
        uniqueId={uniqueId}
        players={players}
        entryFee={entryFee}
      />
    </>
  );
};

export default FightButton;
