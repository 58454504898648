import { socket } from 'config';
import { useState } from 'react';
import getAccount from 'store/hooks/getAccount';

const Balance = () => {
  const { address, balance } = getAccount();
  const [claimAmount, setClaimAmount] = useState<number | undefined>();
  const [waitResponse, setWaitResponse] = useState({
    status: '',
    message: ''
  });
  const [error, setError] = useState<string>('');

  const handleChange = (value: number) => {
    if (value <= balance) {
      setClaimAmount(value);
      setError('');
    } else {
      setError('I do not have enough estar.');
    }
    if (waitResponse.status !== '') {
      setWaitResponse({
        status: '',
        message: ''
      });
    }
  };

  const setMax = () => {
    if (balance > 0) {
      setClaimAmount(balance);
    }
    if (error) {
      setError('');
    }
  };

  const claimReward = () => {
    setError('');
    setWaitResponse({ status: 'waiting', message: '' });
    socket.emit(
      'claimUserReward',
      { address, amount: claimAmount },
      (response: any) => {
        if (response.status === 'SUCCESS') {
          setWaitResponse({
            status: response.status,
            message: 'Withdrawal has been approved!'
          });
        } else {
          setWaitResponse(response);
        }
      }
    );
  };

  return (
    <div>
      <h4 className='fight-page-account-card-body-title text-end'>Balance</h4>
      <h5 className='fight-page-account-card-body-subtitle'>
        You have{' '}
        <span className='fight-page-card-text-white'>
          {balance} <span className='text-blue'>$ESTAR</span>
        </span>{' '}
        in your balance.
      </h5>
      <div className='input-group'>
        <input
          type='number'
          className='form-control fight-page-account-card-body-input'
          placeholder='Claim amount...'
          aria-describedby='basic-addon1'
          value={claimAmount}
          onChange={(e) => handleChange(Number(e.target.value))}
        />
        <div className='input-group-append' onClick={() => setMax()}>
          <span className='input-group-text fight-page-account-card-body-input-max'>
            MAX
          </span>
        </div>
      </div>
      {error && <span className='text-danger'>{error}</span>}
      {waitResponse.status === 'waiting' && (
        <div className='text-warning'>
          <span
            className='spinner-border spinner-border-sm'
            role='status'
            aria-hidden='true'
          ></span>{' '}
          Loading...
        </div>
      )}
      {waitResponse.status === 'SUCCESS' && (
        <span className='text-blue'>{waitResponse.message}</span>
      )}
      {waitResponse.status === 'FAIL' && (
        <span className='text-red'>{waitResponse.message}</span>
      )}
      <button
        className='btn btn-custom mt-3 btn-end'
        disabled={claimAmount === 0 || Boolean(error)}
        onClick={() => claimReward()}
      >
        Claim
      </button>
    </div>
  );
};

export default Balance;
