import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { PropsWithChildren } from 'react';
import { Navigate } from 'react-router-dom';
import { routeNames } from 'routes';
import { adminList } from '../../config';

export const AdminRedirectWrapper = ({ children }: PropsWithChildren) => {
  const { address } = useGetAccountInfo();

  if (!adminList.includes(address)) {
    return <Navigate to={routeNames.hub} />;
  }

  return <>{children}</>;
};
