import React from 'react';
import { CreateCollectionForm } from './CreateCollectionForm';

export const CreateCollection = () => {
  return (
    <div className='card'>
      <div className='card-body'>
        <h3 className='card-title text-center'>Create Collection</h3>
        <CreateCollectionForm />
      </div>
    </div>
  );
};
