import { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import getCollections from '../../store/hooks/getCollection';
import useGetCollection from '../../store/hooks/getCollection';
import { socket } from '../../config';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';

export const Hub = () => {
  const { allCollections } = getCollections();
  const { address } = useGetAccountInfo();
  const navigate = useNavigate();
  useEffect(() => {
    if (!Boolean(address)) {
      navigate('/unlock');
    }
  }, []);
  return (
    <div className='container'>
      <div className='row my-4'>
        <div className='col-12'>
          <h1 className='text-center text-white page-title'>
            Select Challenge
          </h1>
          <hr className='page-title-line' />
        </div>
      </div>
      <div className='row'>
        {allCollections.map((collection, index) => (
          <div className='col-12 col-md-4 mb-2' key={index}>
            <FightCard collection={collection} />
          </div>
        ))}
      </div>
    </div>
  );
};

const FightCard = ({ collection }: { collection: string }) => {
  const [collectionInfo, setCollectionInfo] = useState({
    identifier: '',
    imageUrl: ''
  });
  const collections = collection.split('&');
  const { setActualCollection } = useGetCollection();

  useEffect(() => {
    if (!socket) return;
    socket.emit(
      'getCollectionInfo',
      { collectionString: collection },
      (response: any) => {
        if (response.status === 'SUCCESS') {
          setCollectionInfo(response.data);
        }
      }
    );
  }, []);

  if (collections.length > 1) {
    return (
      <div className='collection-card'>
        <img
          src={collectionInfo.imageUrl}
          alt={collection}
          className='collection-card-image'
        />
        <div className='collection-card-body d-md-flex justify-content-between align-items-center'>
          <h4 className='collection-card-title'>
            {collections[0].split('-')[0]} <span className='text-red'>VS</span>{' '}
            {collections[1].split('-')[0]}
          </h4>
          <Link
            to={`/fight/${collection}`}
            className={'text-decoration-none text-dark'}
            onClick={() => setActualCollection(collections)}
          >
            <button className='btn btn-custom'>Select</button>
          </Link>
        </div>
      </div>
    );
  }
  return (
    <div className='card'>
      <div className='card-body'>
        <div className='text-white card-title'>{collection}</div>
      </div>
    </div>
  );
};
