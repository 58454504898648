import React, { useState } from 'react';
import { faClose, faArrowLeft } from '@fortawesome/free-solid-svg-icons';
import { ReactComponent as SwordIcon } from 'assets/img/swords.svg';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import CustomModal from 'components/CustomModal';
import DisplayNfts from 'components/DisplayNfts';
import { socket } from 'config';
import { IPlayer } from '../types';
import getCollections from '../../../store/hooks/getCollection';

const JoinFight = ({
  show,
  setShow,
  uniqueId,
  entryFee,
  players
}: {
  show: boolean;
  setShow: (arg: any) => void;
  uniqueId: string;
  entryFee: number;
  players: IPlayer[];
}) => {
  const { address } = useGetAccountInfo();
  const [areYouSure, setAreYouSure] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const { actualCollections } = getCollections();

  const joinFight = (ape: string) => {
    if (socket === null) return;
    socket.emit(
      'joinFight',
      { uniqueId: uniqueId, address: address, ape: ape, fee: entryFee },
      (response: any) => {
        if (response.status === 'SUCCESS') setAreYouSure(false);
        if (response.status === 'FAIL') setError(response.message);
      }
    );
  };
  return (
    <>
      <CustomModal
        setShow={setShow}
        show={show}
        backdrop={false}
        size={'lg'}
        title={<h4>Hey, are you sure?</h4>}
        footer={
          <div className='d-block mx-auto'>
            <button
              className={`btn btn-custom btn-small`}
              disabled={players.length === 2 && true}
              onClick={() => {
                setShow(false);
                setAreYouSure(true);
              }}
            >
              <SwordIcon /> <span className='mx-2'>Fight</span>
            </button>
            <button
              className={`btn btn-custom btn-small ml-2`}
              disabled={players.length === 2 && true}
              onClick={() => setShow(false)}
            >
              <FontAwesomeIcon icon={faClose} /> Close
            </button>
          </div>
        }
      >
        <div className='text-center'>
          <p>
            Hey, are you sure you want to pay {entryFee} ESTAR to enter this
            fight?
          </p>
          <p>
            Your balance will decrease by {entryFee} ESTAR when you click on
            NFT.
          </p>
        </div>
      </CustomModal>
      <CustomModal
        setShow={setAreYouSure}
        show={areYouSure}
        backdrop={false}
        size={'xl'}
        title={
          error ? (
            <h4>
              <FontAwesomeIcon icon={faClose} /> Error{' '}
              <FontAwesomeIcon icon={faClose} />
            </h4>
          ) : (
            <h4>Select your NFT.</h4>
          )
        }
        footer={
          <div className='d-block mx-auto'>
            {error ? (
              <button
                className={`btn btn-secondary`}
                onClick={() => setError('')}
              >
                <FontAwesomeIcon icon={faArrowLeft} /> Back
              </button>
            ) : (
              <button
                className={`btn btn-custom btn-small ml-2`}
                disabled={players.length === 2 && true}
                onClick={() => setAreYouSure(false)}
              >
                <FontAwesomeIcon icon={faClose} /> Close
              </button>
            )}
          </div>
        }
      >
        {error ? (
          <h4 className='text-center'>{error}</h4>
        ) : (
          <DisplayNfts func={joinFight} collections={actualCollections} />
        )}
      </CustomModal>
    </>
  );
};

export default JoinFight;
