import React from 'react';
import { IEvent } from 'types/index';
import { decreaseStrings } from 'utils/decreaseStrings';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';

const FightEvents = ({ events }: { events: IEvent[] | undefined }) => {
  const { address } = useGetAccountInfo();
  return (
    <>
      {events?.map((event: IEvent, id) => (
        <div key={id}>
          <hr className='page-title-line' />
          <h4 className='text-center text-white text-custom-font'>
            <span
              style={{ fontWeight: '600' }}
              className={
                event.player2 === address
                  ? 'text-blue'
                  : event.player1 !== address && event.player2 !== address
                  ? undefined
                  : 'text-danger'
              }
            >
              {decreaseStrings(event.player2, 5, 5)}
            </span>{' '}
            was attacked by{' '}
            <span
              style={{ fontWeight: '600' }}
              className={
                event.player1 === address
                  ? 'text-blue'
                  : event.player1 !== address && event.player2 !== address
                  ? undefined
                  : 'text-danger'
              }
            >
              {decreaseStrings(event.player1, 5, 5)}
            </span>{' '}
            and caused {event.damage} damage.
          </h4>
          <h5 className='text-center text-white text-custom-font'>
            <span
              style={{ fontWeight: '600' }}
              className={
                event.player2 === address
                  ? 'text-blue'
                  : event.player1 !== address && event.player2 !== address
                  ? undefined
                  : 'text-danger'
              }
            >
              {decreaseStrings(event.player2, 5, 5)}
            </span>{' '}
            was left with {event.hp} HP.
          </h5>
          <hr />
        </div>
      ))}
    </>
  );
};

export default FightEvents;
