import { socket } from 'config';
import React, { useEffect, useState } from 'react';
import { IPlayer } from '../types';

const FightPlayers = ({
  uniqueId,
  players,
  setPlayers
}: {
  uniqueId: string;
  players: IPlayer[];
  setPlayers: (player: any) => void;
}) => {
  useEffect(() => {
    if (socket === null) return;
    socket.on('newPlayer', (data) => {
      if (data.uniqueId === uniqueId) {
        setPlayers((prev: IPlayer[]) => [...prev, data.player]);
      }
    });
  }, []);
  return (
    <>
      {players?.map((player: IPlayer, id) => (
        <h6 key={id} className='fight-page-card-body-subtitle'>
          {player.address.substring(0, 8) +
            '...' +
            player.address.substring(
              player.address.length - 8,
              player.address.length
            )}
        </h6>
      ))}
    </>
  );
};

export default FightPlayers;
