import {
  faArrowRightFromBracket,
  faBars
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  useGetIsLoggedIn,
  useGetAccountInfo
} from '@multiversx/sdk-dapp/hooks';
import { logout } from '@multiversx/sdk-dapp/utils';
import { Navbar as BsNavbar, NavItem, Nav } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import { routeNames } from 'routes';
import { ReactComponent as XArenaLogo } from '../../../assets/img/xarena.svg';
import { adminList } from 'config';

export const Navbar = () => {
  const { address } = useGetAccountInfo();
  const isLoggedIn = useGetIsLoggedIn();

  const handleLogout = () => {
    logout(`${window.location.origin}/unlock`);
  };

  return (
    <BsNavbar className='border-bottom py-3' expand='md'>
      <div className='container-fluid px-0 px-md-5'>
        <Link
          className='navbar-brand mr-0'
          to={isLoggedIn ? routeNames.hub : routeNames.home}
        >
          <XArenaLogo className='xarena-logo' />
        </Link>
        <BsNavbar.Toggle className='text-white'>
          <FontAwesomeIcon icon={faBars} style={{ fontSize: '25px' }} />
        </BsNavbar.Toggle>
        <BsNavbar.Collapse>
          <Nav className='ml-auto align-items-center'>
            {isLoggedIn && (
              <NavItem className='nav-item'>
                <Link to={routeNames.hub} className='nav-link text-white'>
                  Hub
                </Link>
              </NavItem>
            )}
            <NavItem className='nav-item'>
              <Link to={routeNames.history} className='nav-link text-white'>
                History
              </Link>
            </NavItem>
            <NavItem className='nav-item'>
              <Link to={routeNames.dominance} className='nav-link text-white'>
                Dominance
              </Link>
            </NavItem>
            <NavItem className='nav-item'>
              <a  target='_blank' href={"https://estar.gitbook.io/whitepaper-estar/games/xarena"} className='nav-link text-white'>
                Rules
              </a>
            </NavItem>
            {adminList.includes(address) ? (
              <NavItem className='nav-item'>
                <Link to={routeNames.admin} className='nav-link text-white'>
                  Admin
                </Link>
              </NavItem>
            ) : null}
            {isLoggedIn && (
              <NavItem className='nav-item'>
                <button
                  className='btn btn-link text-secondary'
                  onClick={handleLogout}
                >
                  <FontAwesomeIcon icon={faArrowRightFromBracket} />
                </button>
              </NavItem>
            )}
          </Nav>
        </BsNavbar.Collapse>
      </div>
    </BsNavbar>
  );
};
