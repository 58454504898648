import Account from './Components/Account';
import DisplayFights from './Components/DisplayFights';
import { AccountProvider } from 'store/Account';
import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import { useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { PageNotFound } from '../PageNotFound';
import useGetCollection from '../../store/hooks/getCollection';

const FightPage = () => {
  const { address } = useGetAccountInfo();
  const navigate = useNavigate();
  const { actualCollections } = useGetCollection();
  useEffect(() => {
    if (!Boolean(address)) {
      navigate('/unlock');
    }
  }, []);
  return (
    <AccountProvider>
      <div className='container'>
        <div className='row my-2'>
          <div className='col-12 text-center'>
            <h1 className='page-title'>Fight</h1>
            <h2 className='page-subtitle'>
              Monthly Arena
              <br />
              {actualCollections.length > 1
                ? `${actualCollections[0].split('-')[0]} vs ${
                    actualCollections[1].split('-')[0]
                  }`
                : `${actualCollections[0].split('-')[0]}`}
            </h2>
            <hr className='page-title-line' />
          </div>
        </div>
        <DisplayFights />
        <div className='row'>
          <Account />
        </div>
      </div>
    </AccountProvider>
  );
};

export const Fight = () => {
  const { pathname } = useLocation();
  const { allCollections, actualCollections, setActualCollection } =
    useGetCollection();

  useEffect(() => {
    if (actualCollections.length < 1) {
      setActualCollection(pathname.slice(7).split('&'));
    }
  }, [actualCollections]);
  if (!allCollections.includes(pathname.slice(7))) {
    return <PageNotFound />;
  }
  if (!actualCollections.length) return null;
  return <FightPage />;
};
