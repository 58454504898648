import { useGetAccountInfo } from '@multiversx/sdk-dapp/hooks';
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import NftItem from './Components/NftItem';

const DisplayNfts = ({
  func,
  collections
}: {
  func: (ape: string) => void;
  collections: string[];
}) => {
  const { address } = useGetAccountInfo();
  const [nfts, setNfts] = useState<any[]>([]);
  const [error, setError] = useState<string>('');

  const fetchNfts = async () => {
    const apes: any[] = [];
    try {
      for (const identifier of collections) {
        const response = await axios.get(
          'https://api.multiversx.com/accounts/' +
            address +
            '/nfts?size=100&collection=' +
            identifier
        );
        apes.push(...response.data);
      }
      if (apes.length > 0) {
        setNfts(apes);
      } else {
        setError("You don't have nfts.");
      }
    } catch {
      console.log('Error when i try to fetch EAPES nfts.');
    }
  };

  useEffect(() => {
    fetchNfts();
  }, []);

  if (error) {
    return (
      <div className='row'>
        <div className='col-12'>
          <h4 className='text-white text-center'>{error}</h4>
        </div>
      </div>
    );
  }

  if (nfts.length === 0) {
    return (
      <div className='d-flex justify-content-center'>
        <div className='spinner-border' role='status'></div>
      </div>
    );
  }

  return (
    <div className='row'>
      {nfts?.map((nft: any, id) => (
        <div
          key={id}
          className='col-3 mb-1'
          onClick={() => func(nft.identifier)}
        >
          <NftItem identifier={nft.identifier} name={nft.name} />
        </div>
      ))}
    </div>
  );
};

export default DisplayNfts;
