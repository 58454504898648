import { createContext, useEffect, useState } from 'react';
import { socket } from '../config';

interface ICollection {
  allCollections: string[];
  actualCollections: string[];
  setActualCollection: (collection: any) => void;
  setCollections: (collection: any) => void;
}

export const CollectionsContext = createContext<ICollection>({
  allCollections: [],
  actualCollections: [],
  setActualCollection: () => {},
  setCollections: () => {}
});

export const CollectionsProvider = ({
  children
}: {
  children: JSX.Element;
}) => {
  const [collections, setCollections] = useState([]);
  const [actualCollection, setActualCollection] = useState([]);
  // const collections = getCollections();
  // if (!collections.includes(pathname.slice(7))) {
  //   return <PageNotFound />;
  // }
  useEffect(() => {
    if (!socket) return;
    socket.emit('getCollections', (response: any) => {
      if (response.status === 'SUCCESS') {
        setCollections(response.data);
      }
    });
  }, []);
  return (
    <CollectionsContext.Provider
      value={{
        allCollections: collections,
        actualCollections: actualCollection,
        setActualCollection: setActualCollection,
        setCollections: setCollections
      }}
    >
      {children}
    </CollectionsContext.Provider>
  );
};
