import { ICreateCollection } from '../types';
export const initialState: ICreateCollection = {
  firstIdentifier: '',
  secondIdentifier: '',
  imageUrl: '',
  startId: 0
};

export const formReducer = (
  state: ICreateCollection,
  action: any
): ICreateCollection => {
  switch (action.type) {
    case 'change_input':
      return {
        ...state,
        [action.payload.name]:
          action.payload.name === 'startId'
            ? Number(action.payload.value)
            : action.payload.value
      };
    default:
      return state;
  }
};
