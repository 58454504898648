import React, { useContext } from 'react';
import { CollectionsContext } from '../Collection';

const useGetCollection = () => {
  const {
    allCollections,
    actualCollections,
    setActualCollection,
    setCollections
  } = useContext(CollectionsContext);
  return {
    allCollections,
    actualCollections,
    setActualCollection,
    setCollections
  };
};

export default useGetCollection;
