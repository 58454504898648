import { AdminRedirectWrapper } from './AdminRedirectWrapper';
import { CreateCollection } from './Components/CreateCollection';

const AdminPage = () => {
  return (
    <div className='container'>
      <div className='row my-4'>
        <div className='col-12'>
          <h1 className='text-center page-title'>Admin Page</h1>
          <hr className='page-title-line' />
        </div>
      </div>
      <div className='row'>
        <div className='col-12 col-md-6'>
          <CreateCollection />
        </div>
      </div>
    </div>
  );
};

export const Admin = () => (
  <AdminRedirectWrapper>
    <AdminPage />
  </AdminRedirectWrapper>
);
